import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    FloatingLabel,
    Dropdown,
    Table,
    Alert,
    ToastContainer,
    Toast,
    FormSelect
} from 'react-bootstrap'

import { auth } from "./../../config/firebase";

import { useForm } from 'react-hook-form'
import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'


registerLocale('pt-br', ptBR)

import 'react-datepicker/dist/react-datepicker.css'
import { Link, useParams } from 'react-router-dom';
import Loader from '../../shared/Loader';

// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
    return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )

const strongPassword = (password: any) => {
    return /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
    )
}

export function TotemSelect() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const totemChance = (totemId) => {
      sessionStorage.setItem('currentTotem', totemId)
    }
    return (
        <>
            <Card>
                <Card.Header>
                            <Card.Title as="h5">Totem</Card.Title>

                            <FormSelect
                              className="form-control"
                              onChange={(event) => {
                                const { value } = event.target
                                totemChance(value)
                              }}
                            >
                              <option>Selecione</option>
                              <option value="0137">AT00137</option>
                              <option value="0139">AT00139</option>
                              <option value="0138">AT00138</option>
                              <option value="0140">AT00140</option>
                              <option value="0143">AT00143</option>
                              <option value="0142">AT00142</option>
                              <option value="0141">AT00141</option>
                            </FormSelect>
                    </Card.Header>
            </Card>
        </>
    )



}
