import React, { Component, useCallback, useEffect, useState } from 'react'
import { BASE } from '../../basedata/constants'

import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal';


import { CONFIG } from '../../config/configVariables'

import './event.sass'

import { Link, useParams } from 'react-router-dom'

import { initFlowbite } from 'flowbite'

// import * as XLSX from 'xlsx';
import { utils, read, writeFile } from 'xlsx';
import Dropzone from 'react-dropzone';

import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";


import faceOK from '../../images/icon-smile.svg'
import faceNOK from '../../images/icon-smile-dotted.svg'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MdOutlineFileDownload } from 'react-icons/md';

const MySwal = withReactContent(Swal)

export default function ShowEventProvider() {

  const auth = sessionStorage.getItem('updatx001')
  if (auth !== 'true') {
        sessionStorage.clear();
        window.location.href = '/'
  }

  const dateFormatter = (dateTime:string)=>{

    const options:any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const oldDate = new Date(dateTime)
    return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
  }

    const { eventId, supplierId } = useParams()

    const [loading, setLoading] = useState(false)

    // User list uploader
    const [tempUsersToLoad, setTempUsersToLoad] = useState<any>(null);
    const [tempUserCheck, setTempUserCheck] = useState<any>(null);
    const [usersListColumns, setUsersListColumns] = useState<any>([]);


    //User validation response
    const [responseCounter, setResponseCounter] = useState({
        error: 0,
        success: 0,
        return: false
    });
    const [errorList, setErrorList] = useState<any>({
        simpleList: [],
        fullList: []
    });

    //User list
    const [userCommonList, setUserCommonList] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [usersTotal, setUsersTotal] = useState(0);

    //List handlers
    const [userIdHandlerList, setUserIdHandlerList] = useState<any>([]);
    const [searchBar, setSearchBar] = useState('null')
    const [searchTimeout, setSearchTimeout] = useState<any>(null)
    const [searchData, setSearchData] = useState<any>({});

    const getEventUsers = (searchTerm: any = null) => {
        // /eventUsers/:eventId/:supplierId

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                page,
                pageSize,
                search: searchTerm
            })
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.users_provider}/${eventId}/${supplierId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserCommonList(data.tickets)
                setTotalPages(Math.ceil(data.total / pageSize));
                setUsersTotal(data.total || 0)
            });
    }

    const handleFileUpload = (acceptedFiles: any) => {


        setTempUsersToLoad(null);
        setTempUserCheck(null)

        setResponseCounter({
            error: 0,
            success: 0,
            return: false
        })
        setErrorList({
            simpleList: [],
            fullList: []
        })


        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });

            // Assuming the first sheet is the one you want to parse
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON
            const jsonData = utils.sheet_to_json(sheet);

            // Log rows by columns
            // jsonData.forEach((row) => {
            //   Object.keys(row).forEach((column) => {
            //     console.log(`Column: ${column}, Value: ${row[column]}`);
            //   });
            // });


            setShow(false)
            document.getElementById('orla')?.click()
            setTempUsersToLoad(jsonData);
            setTempUserCheck(jsonData[0])
        };

        fileReader.readAsArrayBuffer(acceptedFiles[0]);
    };

    const verifyUsersJson = () => {
        setLoading(true)
        const newUserField: any = []
        // Object.keys(tempUserCheck).forEach((column, index) => {

        for (const itemList of tempUsersToLoad) {
            let requestObject = {};
            for (let currentColumn of usersListColumns) {
                currentColumn = currentColumn.split('|')
                const currentItemIndex = Object.keys(itemList).indexOf(currentColumn[1])
                if (currentItemIndex >= 0) {
                    requestObject = {
                        ...requestObject,
                        [currentColumn[0]]: itemList[currentColumn[1]]
                    }
                }
                // continue
            }
            const uidx = (new Date().getTime()).toString(36)
            requestObject = {
              ...requestObject,
              "supplierId": supplierId,
              originCompanyId: BASE.COMPANY_DATA.company_id,
              externalId: `bp-${uidx}`,
              gender: 'na',
              document_type: 'na',
              birthDate: "1990-01-01"
            }
            newUserField.push(requestObject)
        }

        console.log('| | | | | ')
        console.log(newUserField)
        console.log('|+ |+ |+ |+ |+ ')


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: eventId,
                supplierId: supplierId,
                userList: newUserField
            })
        };
        fetch(`${BASE.USER.url}${BASE.USER.create_bulk}/${eventId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                // setTempUserCheck(null)
                setLoading(false)
                setResponseCounter({
                    error: data.countError || 0,
                    success: data.countSuccess || 0,
                    return: true
                })
                setErrorList({
                    simpleList: data.errorData.slice(0, 4),
                    fullList: data.errorData
                })
                getEventUsers()
            });
    }

    const deleteUserFromList = (selectedIndex: any) => {
        setTempUserCheck(null)
        const _tempUserCheck = tempUserCheck;
        delete _tempUserCheck[selectedIndex]


        setTimeout(() => {
            if (Object.keys(_tempUserCheck).length < 1) {
                console.log('oiee')
                return setTempUserCheck(null)
            }
            console.log(selectedIndex)
            console.log(tempUserCheck)
            setTempUserCheck(_tempUserCheck)
        }, 100);
    }

    const selectColumnName = (item: any, column: any, key: any) => {
        const _tempUserListColumns: any = usersListColumns
        const regex = new RegExp(`${item.target.value}|${column}|${key}`);

        if (_tempUserListColumns.length > 0) {
            if (regex.test(_tempUserListColumns.join())) {
                _tempUserListColumns[key] = `${item.target.value}|${column}|${key}`
            } else {
                _tempUserListColumns.splice(key, 0, `${item.target.value}|${column}|${key}`)
            }
            setUsersListColumns(_tempUserListColumns)
        } else {
            _tempUserListColumns.splice(key, 0, `${item.target.value}|${column}|${key}`)
        }
    }

    const downloadErrorSheet = () => {
        const worksheet = utils.json_to_sheet(errorList.fullList);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, `error_list.xlsx`);
    }

    const removeUsersFromEvent = (userIds: any) => {


        fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}/${eventId}/${supplierId}`,
        {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                },
                body: JSON.stringify({
                  ids: userIds
                })
            }
        )
        .then((res) => res.json())
        .then((response) => {
          
                const usersId = response.map((item) => item.id)
                console.log(usersId)
            
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "eventId": eventId,
                        "userId": usersId
                    })
                };
                fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        window.location.reload()
                    });
            })
            .catch((error) => {
                console.log(error)
                window.location.reload()
            })

    }

    const removeBiometries = (usersId: any) => {

      if(!confirm('Tem certeza?')){
        return false
      }

  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              tokens: usersId
          })
      };
      fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.remove_token}`, requestOptions)
          .then(response => response.json())

      Swal.fire({
        position: "top",
        icon: "success",
        title: "Biometrias deletadas!",
        showConfirmButton: false,
        timer: 1500
      });
    getEventUsers()

    }

    useEffect(() => {
        getEventUsers()
        initFlowbite()
    }, [page])

    useEffect(() => {
        initFlowbite()
    }, [userCommonList])

    const checkHandler = (userId: any) => {
        let newUserIdList: any = userIdHandlerList
        if (newUserIdList.indexOf(userId) >= 0) {
            newUserIdList = newUserIdList.filter((item: any) => item !== userId)
        } else {
            newUserIdList.push(userId)
        }
        setUserIdHandlerList(newUserIdList)
        getEventUsers()
    };

    function handlePrev() {
        if (page > 1) setPage(page - 1);
    }

    function handleNext() {
        if (page < totalPages) setPage(page + 1);
    }

    function getPageNumbers(): (number | string)[] {
        const numbers: (number | string)[] = [];
        const range = 2; // Número de páginas ao redor da página atual
    
        for (let i = Math.max(2, page - range); i <= Math.min(totalPages - 1, page + range); i++) {
            numbers.push(i);
        }
    
        if (page > 3) numbers.unshift('...');
        if (page < totalPages - 2) numbers.push('...');
    
        return [1, ...numbers, totalPages];
    }
    

    const searchBox = (event: any) => {
        const value = event.target.value;
        setSearchBar(value);
        setSearchData(null)

        // Clear the previous timeout
        clearTimeout(searchTimeout);

        // Set a new timeout
        const timeout = setTimeout(() => {
            console.log(`La bosqueta: ${value}`)
            getEventUsers(value)
        }, 1000);

        setSearchTimeout(timeout);
    }

    const sendMessage = (idsToSend: any, messageType: any) => {


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: messageType,
                userList: idsToSend
            })
        };
        fetch(`${BASE.JAIMINHO.url}${BASE.JAIMINHO.send}`, requestOptions)
            .then(data => {
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Mensagem Enviada",
                    showConfirmButton: false,
                    timer: 1500
                  });
            });

    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const [onboardingLinkText, setOnboardinLinkText] = useState({
        buttonText: 'Link Onboarding', itemToken: ''
    })
    const getOnboardingLink = (token: any) => {

        navigator.clipboard.writeText(`${BASE.onboarding.base_url}/?token=${token}&cred=true`)

        setOnboardinLinkText({buttonText: 'Link Copiado!', itemToken: token})
        setTimeout(() => {
            setOnboardinLinkText({buttonText: 'Link Onboarding', itemToken: ''})
        }, 2000);
        return
    }


    const [eventDetail, setEventDetail] = useState<any>(null);
    const [eventDataTransformed, setEventDataTransformed] = useState<any>({})

    const dateConvert = (date: any) => {
        const startDateTime = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        setEventDataTransformed({
            dataTransformDay: startDateTime.getDate().toString().padStart(2, '0'),
            dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short" }).format(startDateTime),
            dataTransformTime: `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`,
        })
        return `${startDateTime.toLocaleDateString(
            'pt-br'
        )} ${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`
    }

    const getEventDetail = () => {
        const requestOptions = {
            method: 'Get',
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                dateConvert(data.event.startDateTime)
                setEventDetail(data.event)
            });
    }

    useEffect(() => {
        getEventDetail()
    }, [])


    const getUserImage = (token: any) => {
      fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.get_image_biometry}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              token: token
          })
      })
          .then((resg) => resg.json())
          .then((response) => {

              const userImage = response.image ? `data:image/png;base64,${response.image}`:`https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=${BASE.onboarding.base_url}/?token=${token}&cred=true`

              setLoading(false)
              // generateLog({ affectationId: token }, 'download user Image')
              // setCurrentUserImage(response.image)

              MySwal.fire({
                  html:  <img
                  src={userImage}
                  className="qr-code"
                    alt="Pessoa"
                  />,
                  confirmButtonColor: '#ccc',
                })
          })
          .catch((error) => {
              setLoading(false)
              console.log(error)
          })
  }

  const downloadTickets = (supplierId) => {

    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_report}/${supplierId}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
    },
    })
    .then((resg) => resg.json())
    .then((response) => {

      const downloadList = response.map((item) => {
  
        return {
          nome: `${item.firstName} ${item.lastName}`,
          empresa: item.groupName || 'na',
          documento: item.document || 'na',
          entrada: item.entrance !== null ? dateFormatter(item.entranceDate):'--',
          funcao: item.function || 'na',
          setor: item.sector || 'na'
        }
      })

      const worksheet = utils.json_to_sheet(downloadList);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'relatorio');
      writeFile(workbook, `relatorio.xlsx`);
    })
    .catch((error) => {
      console.log(error)
    })



  }
  
    return (
        <>
            <div className='all-page sm:ml-64'>
                <div className='all-page-content'>

                    <div className='grid lg:grid-cols-8 md:grid-cols-3 gap-4 mb-4'>
                        <div className="col-span-3">
                            {/* <EventHeaderSnippet /> */}
                        </div>

                        {/* enviar arquivo */}
                        <div id="default-modal" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                            <div className="relative w-full max-w-md max-h-full">
                                <div className="relative bg-white rounded-lg shadow">
                                    <button id="orla" type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <div className="px-6 py-6 lg:px-8">
                                        <h3 className="mb-4 text-xl font-medium text-gray-900 ">Envie um arquivo</h3>

                                        <div className='card'>
                                            <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} className="modal-drag-drop">
                                                        <input {...getInputProps()} />
                                                        <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou click para selecionar um</p>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <a href="/samples/planilha_exemplo.xlsx" className="sample-link">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"></path>
                                                </svg>
                                            </span>baixar exemplo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal className='dropfile-modal' show={show} onHide={handleClose}>
                            <Modal.Header>
                                <h3>Envie um arquivo</h3>
                                <i><LiaTimesSolid /></i>
                            </Modal.Header>
                            <Modal.Body>

                                <div className="dropfile-box">
                                    <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="modal-drag-drop">
                                                <input {...getInputProps()} />
                                                <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou click para selecionar um</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>

                                <a href="/samples/planilha_exemplo.xlsx" className="sample-link">
                                    <i><LiaFileDownloadSolid /></i>
                                    baixar exemplo
                                </a>
                            </Modal.Body>
                        </Modal>

                        <div className="event-action-top">

                            {eventDetail && (
                                <div className="event-action-top-info">
                                    <div className="square-button rounded-sm">
                                        <span className="event-info-month">
                                            {eventDataTransformed.dataTransformMonth}
                                        </span>
                                        <span className="event-info-date">
                                            {eventDataTransformed.dataTransformDay}
                                        </span>
                                    </div>
                                    <div className="square-button event-title rounded-sm">
                                        <span className="event-info-time">
                                            {eventDataTransformed.dataTransformTime}
                                        </span>
                                        <span className="event-info-title">
                                            {eventDetail.title}
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className='event-action-top-buttons'>

                            <div onClick={() => downloadTickets(supplierId)} className="square-button call-buttom rounded-sm">
                                    <i className='custom-icon'>
                                        <MdOutlineFileDownload />
                                    </i>
                                    <span>Relatório</span>
                                </div>

                           
                                <div onClick={handleShow} className="square-button call-buttom rounded-sm">
                                    <i className='custom-icon'>
                                        <PiMegaphoneLight />
                                    </i>
                                    <span>Convocar</span>
                                </div>


                                <div className="square-button all-event-buttom rounded-sm">
                                    <span className="big-counter">{usersTotal}</span>
                                    <span>Prestadores</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    {(tempUserCheck && !responseCounter.return) && (

                        <div className="upload-check-table">
                            <h2 className='table-title'>Verifique os campos e dados enviados</h2>
                            <div className="relative overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Coluna no Arquivo
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Pré-visualização
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Campo
                                            </th>
                                            <th scope="col" className="px-6 py-3">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.keys(tempUserCheck).map((column, key) => (

                                            <tr key={key} className="bg-white border-b  ">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                    {column}
                                                </th>
                                                <td>
                                                    {tempUserCheck[column]}
                                                </td>
                                                <td>
                                                    <select
                                                        onChange={(item) => selectColumnName(item, column, key)}
                                                    >
                                                        <option>Selecione</option>
                                                        <option value="name">Nome</option>
                                                        <option value="document">CPF</option>
                                                        <option value="email">E-mail</option>
                                                        <option value="phoneNumber">Celular</option>
                                                        <option value="sector">Setor</option>
                                                        <option value="function">Função</option>
                                                        <option value="externalId">Código Interno</option>
                                                        <option value="company">Empresa</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <button onClick={() => deleteUserFromList(column)} className="btn-muted btn-exclude">excluir</button>
                                                </td>
                                            </tr>

                                        )
                                        )
                                        }


                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} className='align-right'>
                                                {loading ? (
                                                    <div className="bar-loader active"></div>
                                                ) : (
                                                    <button onClick={() => verifyUsersJson()} type="button" className="button-default rounded-md">
                                                        Salvar
                                                        <svg className='button-icon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 16">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2.707 14.293 5.586-5.586a1 1 0 0 0 0-1.414L2.707 1.707A1 1 0 0 0 1 2.414v11.172a1 1 0 0 0 1.707.707Z" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    )
                    }


                    {responseCounter.return &&
                        <>
                            <h2 className='table-title'>Dados enviados com sucesso!</h2>
                            <Row>

                                <Col sm={6}>

                                    <div className="sample-error-list">
                                        <table>
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                                <tr>
                                                    <th scope="col">
                                                        Cadastros com erro
                                                    </th>
                                                    <th scope="col">
                                                        Erro
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {errorList.simpleList &&
                                                    errorList.simpleList.map((item: any, index) => (
                                                        <tr key={index} className="bg-white">
                                                            <th scope="row">
                                                                <div>
                                                                    <div className="text-base font-semibold">{item.name || '--'}</div>
                                                                    <div className="font-normal text-gray-500">{item.document || '--'}</div>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                {item.message}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4} className='align-right'>
                                                        <button onClick={() => downloadErrorSheet()} type="button" className="button-default btn-info rounded-md">
                                                            Baixar Todos
                                                            <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1.707 2.707 5.586 5.586a1 1 0 0 0 1.414 0l5.586-5.586A1 1 0 0 0 13.586 1H2.414a1 1 0 0 0-.707 1.707Z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>



                                </Col>
                                <Col sm={3}>

                                    <div className="show-numbers error-numbers rounded-lg">
                                        <div className="big-numbers">
                                            {responseCounter.error || 0}
                                        </div>
                                        <p className="numbers-description">
                                            Pessoas com erro no cadastro
                                        </p>
                                    </div>


                                </Col>
                                <Col sm={3}>

                                    <div className="show-numbers rounded-lg">
                                        <div className="big-numbers">
                                            {responseCounter.success || 0}
                                        </div>
                                        <p className="numbers-description">
                                            Pessoas cadastradas com sucesso!
                                        </p>
                                    </div>

                                </Col>
                            </Row>




                        </>

                    }

                    <div className="people-table">
                        <h2 className='table-title'>Pessoas convocadas</h2>

                        <div className="table-action-bar">

                            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="button-dropdown" type="button">
                                Ações
                                <GoChevronDown />
                            </button>


                            <div id="dropdown" className="hidden dropdown-box">

                                <ul className="dropdown-list" aria-labelledby="dropdownDefaultButton">

                                    <li>
                                        <a onClick={() => sendMessage(userIdHandlerList, 'email')} className="block px-4 py-2 hover:bg-gray-100">Enviar E-mail</a>
                                    </li>

                                </ul>

                                <ul className="dropdown-list dropdown-list-hr" aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a onClick={() => removeBiometries(userIdHandlerList)} className="block px-4 py-2 hover:bg-gray-100">Remover Biometria</a>
                                    </li>
                                    <li>
                                        <a onClick={() => removeUsersFromEvent(userIdHandlerList)} className="block px-4 py-2 hover:bg-gray-100">Excluir</a>
                                    </li>
                                </ul>
                            </div>



                            <div className="table-action-search">
                                <label htmlFor="table-search" className="sr-only">Search</label>
                                <i>
                                    <PiMagnifyingGlassLight />
                                </i>
                                <input onChange={searchBox} type="text" id="table-search-users" placeholder="Buscar usuários (Nome ou CPF)" />
                            </div>
                        </div>

                        <Table responsive hover>
                            <thead className="table-people-header">
                                <tr>
                                    <th scope="col">
                                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500    focus:ring-2  " />
                                        <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                    </th>
                                    <th scope="col">
                                        Dados
                                    </th>
                                    <th scope="col">
                                        Biometria
                                    </th>
                                    <th scope="col">
                                        Função
                                    </th>
                                    <th scope="col">
                                        Setor
                                    </th>
                                    <th colSpan={2} scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='table-people-data'>
                                {userCommonList?.length > 0 ? (
                                    userCommonList.map((item: any) => (
                                        <tr key={item.document}>
                                            <td>
                                                <div className="flex items-center">
                                                    <input checked={userIdHandlerList.indexOf(item.hikId) >= 0 ? true : false} onClick={() => checkHandler(item.hikId)} id={item.hikId} type="checkbox" />
                                                    <label htmlFor={item.hikId} className="sr-only">checkbox</label>
                                                </div>
                                            </td>
                                            <td scope="row" className="table-people-info">
                                                <img onClick={() => getUserImage(item.token)}  src={item.biometric ? faceOK:faceNOK} alt={item.firstName} />
                                                <div>
                                                    <h3>{item.firstName} {item.name}</h3>
                                                    <h4>{item.document}</h4>
                                                </div>
                                            </td>

                                            <td onClick={() => removeBiometries([item.token])}>
                                                <i className={`status-circle ${item.biometric ? 'ok':'nok'}`}>
                                                  </i> {item.biometric ? 'OK (excluir?)':'FALTA'}
                                            </td>

                                            <td>
                                                {item.function || 'N/A'}
                                            </td>
                                            <td>
                                                {item.sector || 'N/A'}
                                            </td>
                                            <td className='table-people-single-action'>
                                                <p className='share-text'>enviar onboaridng:</p>

                                                <button onClick={() => sendMessage([item.hikId], 'email')} type="button" className="button-default button-share rounded-md">
                                                    e-mail
                                                </button>

                                                <button onClick={() => getOnboardingLink(item.token)} type="button" className="button-default button-share rounded-md">

                                                    {item.token === onboardingLinkText.itemToken ? onboardingLinkText.buttonText : 'Link Onboarding'}
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => removeUsersFromEvent([item.hikId])} className='fake-link soft-exclude'>
                                                    excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <div className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 text-center" role="alert">
                                                <span className="font-medium">:(</span> Nenhuma pessoa encontrada para o evento.
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                            {userCommonList?.length > 0 &&
                            <tfoot>
                                <tr>
                                    <td colSpan={8}>
                                        <nav aria-label="pagination">
                                            <ul className="flex pagination items-center justify-center h-10 text-base">
                                                <li className="inline-block">
                                                    <button
                                                        disabled={page === 1}
                                                        onClick={handlePrev}
                                                        className="flex items-center justify-center px-4 h-12 text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 transition-colors duration-200"
                                                    >
                                                        <span className="sr-only">Previous</span>
                                                        <IoIosArrowBack />
                                                    </button>
                                                </li>
                                                {getPageNumbers().map((pageItem: any, index: number) => (
                                                    <li key={index} className="inline-block">
                                                        {pageItem === '...' ? (
                                                            <span className="text-lg font-medium text-black">...</span>
                                                        ) : (
                                                            <button
                                                                className={`flex items-center justify-center px-4 h-12 text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 transition-colors duration-200 ${page === pageItem ? 'bg-gray-100 text-gray-700' : ''}`}
                                                                onClick={() => pageItem !== '...' && setPage(pageItem)}
                                                            >
                                                                {pageItem}
                                                            </button>
                                                        )}
                                                    </li>
                                                ))}
                                                <li className="inline">
                                                    <button
                                                        disabled={page === totalPages}
                                                        onClick={handleNext}
                                                        className="flex items-center justify-center px-4 h-12 text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 transition-colors duration-200"
                                                    >
                                                        <span className="sr-only">Next</span>
                                                        <IoIosArrowForward/>
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </td>
                                </tr>
                            </tfoot>
                            }
                        </Table>
                    </div>
                </div>
            </div>

        </>
    )
}
