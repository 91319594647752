import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import graphTest from '../../images/test-graph.png'

import './DashboardCoordinator.sass'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'
import { EventList } from '../Events/eventList'

import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SupplierUsersCountCard } from '../components/SupplierUsersCountCard'
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdCompareArrows } from "react-icons/md";


function DashLocal() {


  const supplierId = sessionStorage.getItem('company')
  const userCompanyId = sessionStorage.getItem('originCompany')
  
  const [eventData, setEventData] = useState<any>({})

  const [supplierdata, setSupplierdata] = useState<any>({})
  const [userBiometryCount, setUserBiometryCount] = useState<any>({})
  
  const getNextEvent = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.next_event_by_company_info}/${userCompanyId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setEventData(response.event)
        getEventGroups(response.event.eventCode)
        getEventUsers(response.event.eventCode)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventUsers = (eventId) => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_users_count}/${eventId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setUserBiometryCount(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventGroups = (eventId) => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_suppliers}/${eventId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setSupplierdata(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getNextEvent()
  }, [])


  const dateFormatter = (dateTime:string)=>{

    const options:any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const oldDate = new Date(dateTime)
    return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
  }

    return (
      <div className="main-content">
        <div className="main-event">
          <div className="event-header">
            <div className="event-data">
              <h1 className="event-name">{eventData?.title}</h1>
              <h2 className="event-date">{dateFormatter(eventData?.startDateTime)}</h2>
            </div>


          </div>
          <div className="event-content">
  
          {/* <div className="event-ticket-total event-header-content-box">
              <div className="event-header-content-box-main">
                Pessoas Convocadas
                <div className='event-ticket-counter'>9999</div>
              </div>
              <div className='event-ticket-button'>
                    visualizar
                    <div className="event-icon">
                      <AiOutlineUsergroupAdd/>
                    </div>
                  </div>
            </div> */}

            
  
            <div className="event-biometry-total event-header-content-box">
              <div className="event-header-content-box-main">
                Pessoas Convocadas
                <div className='event-ticket-counter'>{userBiometryCount.totalUsers}</div>
              </div>
              <div className="event-icon biometry-firulita">
                      <MdCompareArrows/>
                    </div>
              <div className="event-header-content-box-main">
                Biometrias Feitas
                <div className='event-ticket-counter'>{userBiometryCount.totalBiometry === 0 ? '00':userBiometryCount.totalBiometry}</div>
              </div>
              <Link to={`/provider/events/${eventData?.eventCode}/${supplierId}`} className="event-ticket-button">
                visualizar
                <div className="event-icon">
                  <FaUsersViewfinder/>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>
    )
}

export default DashLocal
