import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, Button, Form, ToastContainer, Toast } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import {
    BsEmojiSunglasses,
    BsSun,
    BsPeople,
    BsPersonBoundingBox,
    BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { UserCreate } from '../components/userCreate'
import { MultipleUserCreate } from '../components/multipleUserCreate'
import ShowEventProvider from '../Events/showEventProvider'

import faceOK from '../../images/icon-smile.svg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreateAdmin } from '../components/userCreateAdmin'

import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";

function TerminalList() {
  
  const steps = [
    { name: 'Validando Dispositivos', status: 'done', timestamp: '11:17' },
    { name: 'Limpando Dispositivos', status: 'done', timestamp: '11:49' },
    { name: 'Carregando Sincronização', status: 'done', timestamp: '12:03' },
    { name: 'Iniciando Sincronização', status: 'done', timestamp: '12:16' },
    { name: 'Sincronizando', status: 'processing', timestamp: '13:28' },
  ];

  const getBadgeColor = (status) => {
    switch (status) {
      case 'done':
        return 'linear-gradient(145deg, #6dd5ed, #2193b0)'; // Blue gradient
      case 'processing':
        return 'linear-gradient(145deg, #f6d365, #fda085)'; // Orange gradient
      case 'idle':
        return 'linear-gradient(145deg, #c3cfe2, #cfd9df)'; // Gray gradient
      default:
        return '#aaa'; // Default color
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'offline':
        return '#7e0c32'; // Red gradient
      case 'online':
        return '#0c7e0f'; // Green gradient
      case 'loading':
        return '#f6d365'; // White gradient
      default:
        return '#fff'; // Default color
    }
  };

  const [ipData, setIpData] = useState([
    { ip: 'bebox_0137', port: '0', status: 'online' },
    { ip: 'bebox_0137', port: '0', status: 'online' },
    { ip: 'bebox_0137', port: '0', status: 'online' },
    { ip: 'bebox_0137', port: '0', status: 'online' },
    { ip: 'bebox_0137', port: '0', status: 'online' },
    { ip: 'bebox_0137', port: '0', status: 'online' },
    { ip: 'bebox_0137', port: '0', status: 'online' },
  ])

  const [faceTotal, setFaceTotal] = useState(0)

  // const ipData = [
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  //   { ip: 'bebox_0137', port: '0', status: 'online' },
  // ];


  const getDistStatus = () => {

    fetch(`${BASE.EVENT.url}${BASE.EVENT.get_sync_status}/teste`, {
        method: 'GET',
        headers: {
            Authorization: CONFIG.API_KEY
        }
    })
        .then((resg) => resg.json())
        .then((response) => {
          const temp_terminals:any = []
          response.terminals.map((item) => {
            temp_terminals.push({ ip: item.bebox_name, port: item.count, status: 
              (response.total === item.count) ? 'online':'loading'
            })
          })
          setIpData(temp_terminals)
          setFaceTotal(response.total)
        })
        .catch((error) => {
            console.log(error)
        })
}


useEffect(() => {
  getDistStatus()
}, [])

    return (
        <>
            <h2 className='table-title'>Dispositivos do evento</h2>
            <div className="timeline-container">
              <div className="timeline">
                {steps.map((step, index) => (
                  <div key={index} className="timeline-item">
                    <div className="timeline-badge" style={{ background: getBadgeColor(step.status) }}>
                    </div>
                      <span className="timeline-timestamp">{step.timestamp}</span>
                    <div className="timeline-content">
                      <h5>{step.name}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="ip-boxes-container">
            <div className="ip-box ip-label loading" style={{ background: getStatusColor('na') }}>
          <label>Faces Totais:</label>
          <p>{faceTotal}</p>
        </div>

        {ipData.map((data, index) => (
          <div key={index} className={`ip-box ${data.status}`} style={{ background: getStatusColor(data.status) }}>
          <label>{data.ip}</label>
          <p>{data.port}</p>
        </div>
        ))}
      </div>
        </>
    )
}

export default TerminalList
