import React, { useEffect, useState } from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

import './sidebar.sass'

import { PiHouseSimple } from "react-icons/pi";
import { BsCalendar2Event } from "react-icons/bs";
import { GrGroup, GrUserSettings } from "react-icons/gr";
import { LiaUsersCogSolid } from "react-icons/lia";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { TbLockCog } from "react-icons/tb";



import bepassLogo from '../assets/images/bepass_logo_default.png'
import tokioLogo from '../assets/images/logo-arena-mrv.png'
import fanLogo from '../assets/images/fanbase-logo.png'

const SideBar = () => {

  const activeRole = sessionStorage.getItem('role')
  console.log(activeRole)

  const sessionId = sessionStorage.getItem('id')
  console.log(sessionId)

  const sessionName = sessionStorage.getItem('name')

  const [orgLogo, setOrgLogo] = useState(tokioLogo)
  const [currentCompanyName, setCurrentCompanyName] = useState('Flamengo')


  function logMeOut() {
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('gate')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('role')
    sessionStorage.removeItem('company')
    return (window.location.href = '/')
  }

  useEffect(() => {
    const companyId = sessionStorage.getItem('company')
    const originCompany = sessionStorage.getItem('originCompany')
    if (companyId === '8e0638ba-bad9-4218-ad65-d0dbb2ea6fa6') {
      setOrgLogo(fanLogo)
    }

    if(originCompany === 'f01d22f5-56ba-473e-9cc2-739e0ba6abdf'){
      setCurrentCompanyName('Fluminense')
    }

  }, [])

  return (
    <div className="custom-navBar">
      <CDBSidebar
        breakpoint={991}
        className="my-sidebar"
        textColor="white"
        backgroundColor="black"
        toggled={true}
        minWidth={"100"}
        maxWidth={"300"}
      >

        <div className="club-identification">
          {currentCompanyName}
        </div>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>

            {/*
            <NavLink to="/dashboard" >
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink> */}
            {activeRole === 'fanbase' ? (
              <>
                <NavLink to="/dashboard/fanbase" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'admin' ? (
              <>
                <NavLink to="/dashboard" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/organizations" >
                  <CDBSidebarMenuItem icon="star">Organizações</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'localadmin' ? (
              <>
                <NavLink to="/home" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>

                <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="star">Usuários</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : activeRole === 'coordinator' ? (
              <>
                  <NavLink to="/dashboard/coordinator" >
                    <li>
                        <span className="sidebar-icon"><PiHouseSimple/></span>
                        <span className='item-content'>Dashboard</span>
                    </li>
                  </NavLink>

                {sessionName !== 'beops' &&
                  <NavLink to="/events" >
                    <li>
                        <span className="sidebar-icon"><BsCalendar2Event/></span>
                        <span className='item-content'>Eventos</span>
                    </li>
                </NavLink>
                }

                <NavLink to="/providers" >
                <li>
                        <span className="sidebar-icon"><AiOutlineUsergroupAdd/></span>
                        <span className='item-content'>Empresas</span>
                    </li>
                </NavLink>

                {sessionName !== 'beops' &&
                <NavLink to="/terminals" >
                    <li>
                        <span className="sidebar-icon"><LiaUsersCogSolid/></span>
                        <span className='item-content'>Dispositivos</span>
                    </li>
                </NavLink>
                }
                {sessionName !== 'beops' &&
                <NavLink to="/user/block" >
                    <li>
                        <span className="sidebar-icon"><TbLockCog/></span>
                        <span className='item-content'>Block list</span>
                    </li>
                </NavLink>
                }
              </>
            ) : activeRole === 'supplier' ? (
              <>
              <NavLink to="/home" >
                <li>
                    <span className="sidebar-icon"><PiHouseSimple/></span>
                    <span className='item-content'>Home</span>
                </li>
              </NavLink>

            <NavLink to="/events/supplier" >
            <li>
                    <span className="sidebar-icon"><AiOutlineUsergroupAdd/></span>
                    <span className='item-content'>Eventos</span>
                </li>
            </NavLink>

          </>
            ) : activeRole === 'local' ? (
              <>
                <NavLink to="/dashboard/local" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>


                <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="users">Usuários</CDBSidebarMenuItem>
                </NavLink>
              </>
            ) :
              <>
                <NavLink to="/events/08884491-c899-4fb8-a541-676e97f502e7" >
                  <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
                </NavLink>
                {/* <NavLink to="/user/create" >
                  <CDBSidebarMenuItem icon="users">Cadastrar Usuários</CDBSidebarMenuItem>
                </NavLink> */}
              </>

            }

        </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div >
  );
};


export default SideBar;
